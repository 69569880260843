import { get, post, downloadG } from '@/utils/request';

// -------接口参数约束------
// -------接口列------

/**
 * 【公共】获取Oss图片上传临时Token
 * @notes 
 */
export function getPictureOssUploadAccessToken() {
    return get('/third/oss/getPictureOssUploadAccessToken');
}

