
import { Component, Prop, Vue, Emit } from 'vue-property-decorator'
import { Message } from 'element-ui'
import { getPictureOssUploadAccessToken } from "@/api/third/ossManager"
import { hideLoading, showLoading } from '@/utils/loading'
const OSS = require('ali-oss')

@Component({
    name: 'JtAvatarUpload'
})
export default class JtAvatarUpload extends Vue {
    @Prop({ required: true }) image!: string

    get currentImage(){
        return this.image
    }

    set currentImage(val:any){
        this.$emit('update:image', val)
    }

    ossObj: {
        expiration:string
        securityToken:string
        requestId:string
        accessKeyId:string
        accessKeySecret:string
        assumedRoleUserId:string
        region:string
        regionSuffix:string
        bucket:string
        ossPath:string
        domain:string
    } = {
        expiration: "",
        securityToken: "",
        requestId: "",
        accessKeyId: "",
        accessKeySecret: "",
        assumedRoleUserId: "",
        region: "",
        regionSuffix: "",
        bucket: "",
        ossPath: "",
        domain: ""
    }

    mounted(){
        getPictureOssUploadAccessToken().then(res => {
            this.ossObj = res.data
        })
    }

    @Emit("handleSuccess")
    handleSuccess(result: any){}
    @Emit("handleError")
    handleError(){}

    handleImgUpload(option:any){
        this.uploadFile(option.file);
    }

    beforeAvatarUpload(file:any){
        let imgList = [
        "image/jpg",
        "image/JPG",
        "image/jpeg",
        "image/PNG",
        "image/png",
        "image/gif",
        "image/webp",
        "image/WEBP",
        "image/svg",
        "image/pjpeg",
        "image/ico",
        "image/tiff"];
        const isImg = imgList.indexOf(file.type) > 0;
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isImg) {
            Message.error('请上传jpg,jpeg,png,gif,webp格式的图片');
        }
        if (!isLt2M) {
            Message.error('上传头像图片大小不能超过 2MB!');
        }
        return isImg && isLt2M;
    }

    async uploadFile(file:any) {
        let ossConfig = {
            region: this.ossObj.region,
            accessKeyId: this.ossObj.accessKeyId,
            accessKeySecret: this.ossObj.accessKeySecret,
            bucket: this.ossObj.bucket,
            stsToken: this.ossObj.securityToken,
            secure: true
        };
        let ossPath = this.ossObj.ossPath;
        let domain = this.ossObj.domain;
        const client = new OSS(ossConfig);
        try {
            let fileName = Date.now() + file.name.substring(file.name.lastIndexOf("."));
            showLoading({message: '上传中',className: 'upload-container',lazy:0});
            const result = await client.multipartUpload(ossPath + '/' + fileName, file);
            hideLoading();
            if (result && result.res && result.res.requestUrls) {
                result.url = domain + result.name;
                console.log(result)
                this.currentImage = result.url
                this.handleSuccess(result);
            } else {
                this.handleError();
            }
        } catch (e) {
            hideLoading();
            console.error(e);
            this.handleError();
        }
    }
}
